<template>
  <div class="down-up-popup">
    <default-dialog
      v-bind="$attrs"
      @close="$emit('close')"
      @confirm="$emit('confirm')"
    >
      <template slot="body">
        <slot name="body"> </slot>
      </template>

      <template slot="footer">
        <slot name="footer"></slot>
      </template>
    </default-dialog>
  </div>
</template>

<script>
import DefaultDialog from '../layouts/DefaultDialog.vue';

export default {
  components: { DefaultDialog },
};
</script>

<style>
.down-up-popup .layout_contain {
  animation: fadeInUp 0.5s;
}
.layout_popup {
  display: block;
}
</style>
