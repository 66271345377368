export default {
  path: '/search',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: 'main',
      name: 'search',
      label: '검색 메인',
      component: () =>
        import(/* webpackChunkName: "search" */ '@Views/search/SearchView.vue'),
    },
    {
      path: 'result',
      name: 'search.result',
      label: '검색 결과',
      component: () =>
        import(
          /* webpackChunkName: "search" */ '@Views/search/SearchResultView.vue'
        ),
    },
    {
      path: 'result/product',
      name: 'search.result.all.product',
      label: '상품 전체 검색 결과',
      component: () =>
        import(
          /* webpackChunkName: "search" */ '@Views/search/SearchResultAllProductView.vue'
        ),
    },
    {
      path: 'result/brand',
      name: 'search.result.all.brand',
      label: '브랜드 전체 검색 결과',
      component: () =>
        import(
          /* webpackChunkName: "search" */ '@Views/search/SearchResultAllBrandView.vue'
        ),
    },
    {
      path: 'result/event',
      name: 'search.result.all.event',
      label: '이벤트 전체 검색 결과',
      component: () =>
        import(
          /* webpackChunkName: "search" */ '@Views/search/SearchResultAllEventView.vue'
        ),
    },
    {
      path: 'result/coupon',
      name: 'search.result.all.coupon',
      label: '쿠폰 전체 검색 결과',
      component: () =>
        import(
          /* webpackChunkName: "search" */ '@Views/search/SearchResultAllCouponView.vue'
        ),
    },
  ],
};
