<template>
  <div class="toast-wrap">
    <ul class="toast" :style="position">
      <KCToastItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        @close="handleCloseToast"
      />
    </ul>
  </div>
</template>

<script>
import { ref } from 'vue-demi';
import KCToastItem from './KCToastItem.vue';
import useToast from '@/composables/dialogs/useToast';

export default {
  components: { KCToastItem },
  setup() {
    const width = 300;

    const items = ref([]);

    const toastStore = useToast();

    toastStore.$subscribe((_, state) => {
      items.value = state.items;
    });

    return {
      items,
      position: `width: ${width * 0.8}px; left: 50%; margin-left: -${
        width * 0.4
      }px`,

      handleCloseToast(item) {
        console.log(item);
        toastStore.closeMsg(item);
      },
    };
  },
};
</script>

<style>
.toast {
  position: fixed;
  list-style: none;
  padding: 0;
  bottom: 50px;
  z-index: 999999;
  font-size: 12px;
}
</style>
