<template>
  <down-up-popup :title="$t('Popup.Share')" @close="store.onClose">
    <ul slot="body" class="select_list_box sns_list">
      <li>
        <a href="#" class="kakao02">{{ $t('Popup.Kakao') }}</a>
      </li>
      <li>
        <a class="kakaost" @click="shareKakaoStory">
          <span style="display: none"></span>
          {{ $t('Popup.KakaoStory') }}
        </a>
      </li>
      <li>
        <a class="nline" @click="shareLine">{{ $t('Popup.Line') }}</a>
      </li>
      <li>
        <a class="facebook02" @click="shareFacebook">{{
          $t('Popup.FaceBook')
        }}</a>
      </li>
      <li>
        <a class="tw" @click="shareTwitter">{{ $t('Popup.Twitter') }}</a>
      </li>
      <li>
        <a class="pniter" @click="sharePinterest">{{
          $t('Popup.Pinterest')
        }}</a>
      </li>
      <li class="container">
        <a class="url share-url" @click="clickCopyUrl">{{
          $t('Popup.UrlCopy')
        }}</a>
      </li>
    </ul>

    <template slot="footer">
      <span />
    </template>
  </down-up-popup>
</template>

<script>
import DownUpPopup from './DownUpPopup.vue';
import useShare from '@Composables/dialogs/useShare.js';
import { onMounted, ref } from 'vue-demi';
import SnsLogin from '@/utils/SnsLoginUtils';
import useToast from '@/composables/dialogs/useToast';
const snsLogin = new SnsLogin();

export default {
  components: { DownUpPopup },
  setup(props, context) {
    const { addMsg } = useToast();
    const store = useShare();
    const shareUrl = ref();
    const container = ref();
    const {
      config: { desc, image, title, url },
    } = store;

    const clickCopyUrl = () => {
      store.onClose();
      context.root.$copyText(url);
      addMsg(context.root.$t('Popup.AlertCopy'));
    };

    onMounted(() => {
      // 사용할 앱의 JavaScript 키 설정
      snsLogin.kakaoLoginInit();
      window.LineIt.loadButton();
      // 카카오링크 버튼 생성
      window.Kakao.Share.createDefaultButton({
        container: '.kakao02', // 카카오공유버튼ID
        objectType: 'feed',
        content: {
          title: title, // 보여질 제목
          description: desc, // 보여질 설명
          imageUrl: image, // 콘텐츠 URL
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
      });

      window.Kakao.Story.createShareButton({
        container: '.kakaost span',
        url: url,
        text: title,
      });
    });

    return {
      url,
      store,
      shareUrl,
      container,
      clickCopyUrl,
      doIt() {},
      shareFacebook() {
        window.open(`http://www.facebook.com/sharer/sharer.php?u=${url}`);
      },
      shareKakaoStory() {
        document.querySelector('.kakaost span a').click();
      },
      shareTwitter() {
        // var sendText = '개발새발'; // 전달할 텍스트
        // var sendUrl = 'devpad.tistory.com/'; // 전달할 URL
        // window.open(
        //   'https://twitter.com/intent/tweet?text=' +
        //     sendText +
        //     '&url=' +
        //     sendUrl
        // );

        const urls = [
          'https://twitter.com/intent/tweet',
          `?text=${title}`,
          `&url=${url}`,
        ];

        window.open(urls.join(''));
      },
      sharePinterest() {
        const urls = [
          'http://pinterest.com/pin/create/bookmarklet/',
          `?media=${image}`,
          `&url=${url}`,
          `&is_video=false`,
          `&description=${title}`,
        ];

        const open = window.open(urls.join(''), 'width=800, height=500');

        if (open) {
          open.focus();
        }
      },
      shareLine() {
        window.open(
          'https://social-plugins.line.me/lineit/share?url=' + url,
          'width=800, height=500',
        );
      },

      // shareURL() {
      //   document.querySelector('#clipboardUrl').select();
      //   document.execCommand('copy');

      //   addMsg('클립보드에 복사되었습니다.');
      // },
    };
  },
};
</script>

<style></style>
