<template>
  <li :class="className">{{ item.msg }}</li>
</template>

<script>
import { ref } from 'vue-demi';
export default {
  props: ['item'],
  setup(props, context) {
    const className = ref('');

    setTimeout(() => {
      className.value = 'toast-close';

      setTimeout(() => {
        context.emit('close', props.item);
      }, 500);
    }, 2000);

    return { className };
  },
};
</script>

<style scoped>
.toast li {
  margin: 10px 0 0 0;
  display: block;
  padding: 12px 0px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-weight: normal;
  color: #fff;
  background-color: #202020;
  opacity: 0.8;
  text-align: center;
  font-size: 12px;
  animation-duration: 0.5s;
  animation-name: toast;
}
.toast li.toast-close {
  margin: 50px 0 0 0;
  opacity: 0;
  animation-duration: 0.5s;
  animation-name: close;
}
.toast li:first-child {
  margin-top: 0;
}

@keyframes toast {
  from {
    opacity: 0.5;
    margin: 0;
  }

  to {
    opacity: 0.8;
    margin: 10px 0 0 0;
  }
}

@keyframes close {
  from {
    opacity: 0.8;
    margin: 10px 0 0 0;
  }

  to {
    opacity: 0;
    margin: 0px 0 0 0;
  }
}
</style>
