<template>
  <!--삭제할 상품이 없을 경우 팝업-->
  <div class="layout_popup pick_layout_pop" style="display: block">
    <div class="dim"></div>
    <div class="layout_contain fix_foot_btn">
      <div class="layout_box">
        <div class="pop_body">
          <p class="fontSuit" v-html="msg" />
        </div>
        <div class="pop_foot">
          <button
            type="button"
            class="black_btn width100"
            @click="onClickConfirm"
          >
            {{ $t('Join.Confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAlert from '@Composables/dialogs/useAlert.js';

export default {
  setup() {
    const store = useAlert();
    return store;
  },
};
</script>

<style scoped>
.layout_contain.fix_foot_btn {
  animation: fadeInUp 0.5s;
}
</style>
