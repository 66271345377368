//

Number.prototype.numberFormat = function () {
  return this.toLocaleString('en-US');
};

String.prototype.numberFormat = function () {
  return Number(this).toLocaleString('en-US');
};

// 쿠키 가져오기 함수
export const getCookie = cName => {
  const target = cName + '=';
  const cookies = document.cookie.split(';');
  let value = undefined;

  cookies.forEach(cookie => {
    const idx = cookie.indexOf(target);

    if (idx > -1) {
      const [key, cValue] = cookie.split('=');

      if (key.trim() === cName) value = cValue;
    }
  });

  return value;
};

export const setCookie = (cookie_name, value) => {
  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

  document.cookie = `${cookie_name}=${value};path=/;expires=${date.toGMTString()}`;
};

// 배열 쿠키 가져오기 함수
export const getCookieArray = cName => {
  console.log(cName);
  console.log(decodeURI(localStorage.getItem(cName)) ?? '[]');
  return JSON.parse(decodeURI(localStorage.getItem(cName)) ?? '[]');
};

export const addClassNavDown = node => {
  const classes = node.className.split(' ');

  const idxD = classes.indexOf('nav-down');
  const idxU = classes.indexOf('nav-up');

  if (idxD === -1) classes.push('nav-down');
  if (idxU > -1) classes.splice(idxU, 1);

  node.className = classes.join(' ');
};

export const removeClassNavDown = node => {
  const classes = node.className.split(' ');

  const idxD = classes.indexOf('nav-down');
  const idxU = classes.indexOf('nav-up');

  if (idxD > -1) classes.splice(idxD, 1);
  if (idxU === -1) classes.push('nav-up');

  node.className = classes.join(' ');
};

let lastScrollPosition = 0;

export const toggleNav = () => {
  const currentScrollPosition =
    window.pageYOffset || document.documentElement.scrollTop;

  if (currentScrollPosition < 0) {
    return;
  }

  if (Math.abs(currentScrollPosition - lastScrollPosition) < 60) {
    return;
  }

  const isScrollUp = currentScrollPosition < lastScrollPosition;

  lastScrollPosition = currentScrollPosition;

  document.querySelectorAll('.slid_up').forEach(node => {
    if (!isScrollUp) {
      removeClassNavDown(node);
    } else {
      addClassNavDown(node);
    }
  });
};

export const newScrollEvent = el => {
  let beforePosition = document.documentElement.scrollTop;
  let isScrolling;
  window.addEventListener('scroll', function () {
    window.clearTimeout(isScrolling);
    isScrolling = setTimeout(function () {
      el.value = false;
    }, 66);
  });
  window.addEventListener('scroll', function () {
    let afterPosition = document.documentElement.scrollTop;
    if (afterPosition > 50) {
      if (beforePosition < afterPosition) {
        // 스크롤 위로
        el.value = true;
      } else {
        // 스크롤 아래로
        el.value = false;
      }
    }
    beforePosition = afterPosition;
    return el;
  });
};
