import { reactive, toRefs } from '@vue/composition-api';
import { defineStore } from 'pinia';

export default defineStore('useShare', () => {
  const state = reactive({
    isShowShare: false,
    config: {},
    handleClickSuccess: null,
  });

  function showShare(config = {}, onConfirm) {
    state.config = config;
    state.isShowShare = true;
    state.handleClickSuccess = onConfirm;
  }

  function onClose() {
    state.isShowShare = false;
    state.url = '';

    state.handleClickSuccess?.();
  }

  return { ...toRefs(state), showShare, onClose };
});
