import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import AuthAPI from '@/apis/AuthAPI';
import AuthTokenAPI from '@/apis/AuthTokenAPI';
import { enc, dec } from '@/utils/CryptoJS';

export default defineStore('useUserInfo', () => {
  const info = ref(null);

  const setAuthToken = async id => {
    console.log('setAuthToken');
    if (id && Number(id) && Number(id) > 0) {
      localStorage.setItem('mem_id', id);
      const { data } = await AuthTokenAPI.signToken(id);
      console.log('data', data);
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('accessTokenExpiredAt', data.accessTokenExpiredAt);
      localStorage.setItem('refreshToken', enc(data.refreshToken));
      localStorage.setItem('refreshTokenExpiredAt', data.refreshTokenExpiredAt);
    } else {
      console.log('mem_id not existed');
    }
  };

  const verifyAccessToken = async () => {
    console.log('verifyAccessToken');
    const id = localStorage.getItem('mem_id');
    const accessToken = localStorage.getItem('accessToken');
    console.log('id', id, 'accessToken', accessToken);
    const { data } = await AuthTokenAPI.verifyAccessToken(id, accessToken);
    return data?.success === true;
  };

  const reissueAccessToken = async () => {
    console.log('reissueAccessToken');
    const id = localStorage.getItem('mem_id');
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = dec(localStorage.getItem('refreshToken'));
    const { data } = await AuthTokenAPI.reissueAccessToken(
      id,
      accessToken,
      refreshToken,
    );
    console.log(data);
    return data?.success === true;
  };

  const checkInfo = async () => {
    const id = localStorage.getItem('mem_id');
    const accessTokenExpiredAt = localStorage.getItem('accessTokenExpiredAt');
    const current = new Date().getTime();
    if (id && Number(id) && Number(id) > 0) {
      if (Number(current) < Number(accessTokenExpiredAt) * 1000) {
        console.log('skip check token');
        return;
      }
      try {
        const verified = await verifyAccessToken();
        if (!verified) {
          const verified2 = await reissueAccessToken();
          if (!verified2) {
            localStorage.removeItem('mem_id');
            info.value = null;
          }
        }
      } catch (error) {
        localStorage.removeItem('mem_id');
        info.value = null;
      }
    } else {
      localStorage.removeItem('mem_id');
      info.value = null;
    }
  };

  const getUserInfo = async () => {
    await checkInfo();
    if (info.value !== null) {
      return info.value;
    }

    const id = localStorage.getItem('mem_id');
    if (id) {
      console.log('아이디있음');
      const { data } = await AuthAPI.getMemberInfo(id);

      info.value = data.result_data;
      return info.value;
    }

    return null;
  };

  const clearUserInfo = () => {
    info.value = null;

    localStorage.removeItem('mem_id');
    localStorage.removeItem('push_yn');
  };

  const setUserInfo = async data => {
    await setAuthToken(data.mem_id);
    info.value = data;
  };

  const setInfoAttr = (key, value) => {
    const data = { ...info.value };

    data[key] = value;

    info.value = data;
  };

  return {
    info,
    getUserInfo,
    setUserInfo,
    setInfoAttr,
    clearUserInfo,
    checkInfo,
  };
});
