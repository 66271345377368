import { checkUserLogin } from '@/router';

export default {
  path: '/order',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: '',
      name: 'order',
      label: '주문 결제',
      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(/* webpackChunkName: "order" */ '@Views/orders/OrderView.vue'),
    },
    {
      path: 'complete/:id/:status',
      name: 'order.complate',
      label: '주문 완료',
      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "order" */ '@Views/orders/OrderComplateView.vue'
        ),
    },
    {
      path: 'history',
      name: 'order.history',
      label: '주문/배송 조회',
      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "order" */ '@Views/orders/OrderComplateView.vue'
        ),
    },
  ],
};
