export default {
  path: '/auth',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: 'login',
      name: 'auth.login',
      label: '로그인',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/LoginView.vue'),
    },
    {
      path: 'join/home',
      name: 'auth.join-home',
      label: '회원가입',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/JoinHomeView.vue'),
    },
    {
      path: 'join',
      name: 'auth.join',
      label: '회원가입',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/JoinView.vue'),
    },
    {
      path: 'join2',
      name: 'auth.join2',
      label: '회원가입',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/JoinView2.vue'),
    },
    {
      path: 'join3',
      name: 'auth.join3',
      label: '회원가입',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/JoinView3.vue'),
    },
    {
      path: 'join4',
      name: 'auth.join4',
      label: '회원가입',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/JoinView4.vue'),
    },
    {
      path: 'join5',
      name: 'auth.join5',
      label: '회원가입',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/JoinView5.vue'),
    },
    {
      path: 'error',
      name: 'auth.error',
      label: '회원가입 에러',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/JoinErrorView.vue'),
    },
    {
      path: 'find-id',
      name: 'auth.find.id',
      label: '아이디 찾기',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@Views/auth/finds/FindIdView.vue'
        ),
    },
    {
      path: 'find-pw',
      name: 'auth.find.pw',
      label: '비밀번호 찾기',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@Views/auth/finds/FindPasswordView.vue'
        ),
    },
    {
      path: 'change-pw/:otp',
      name: 'auth.change-pw',
      label: '비밀번호 변경',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@Views/auth/finds/ChangePasswordView.vue'
        ),
    },
    {
      path: 'reset-pw/:mem_id',
      name: 'auth.find.reset.pw',
      label: '비밀번호 찾기',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@Views/auth/finds/ResetView.vue'
        ),
    },

    {
      path: 'login/naver',
      name: 'auth.login.naver',
      label: '네이버 로그인',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@Views/auth/actions/LoginNaver.vue'
        ),
    },
    {
      path: 'find/:type/naver',
      name: 'auth.find.sns.naver',
      label: '네이버 아이디/비밀번호 찾기',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@Views/auth/actions/FindNaver.vue'
        ),
    },
    {
      path: 'find/result/id/:mem_id',
      name: 'auth.find.result.id',
      label: '네이버 아이디/비밀번호 찾기 결과',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@Views/auth/finds/FindResultIdView.vue'
        ),
    },
    {
      path: 'update-auth',
      name: 'auth.update.auth',
      label: '간편로그인시 본인인증 페이지',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@Views/auth/UpdateAuth.vue'),
    },
  ],
};
