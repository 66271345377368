import { axios } from '@/plugins/axios';

export default {
  setPush(params) {
    return axios.post('/common/ajaxSetPush', params);
  },

  getColors() {
    return axios.post('/common/getColors');
  },

  getStyles() {
    return axios.post('/common/getStyles');
  },

  updatePick(params) {
    return axios.post('/common/updatePick', params);
  },

  getShopInfo() {
    return axios.post('/common/getShopInfo');
  },
  getNoticeList(params) {
    return axios.post('/common/getNoticeList', params);
  },
  getBoardCategory() {
    return axios.post('/common/getBoardCategory');
  },
  getFaqList(params) {
    return axios.post('/common/getFaqList', params);
  },
};
