import { reactive, toRefs } from '@vue/composition-api';
import { defineStore } from 'pinia';
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../utils/CommonUtils';

export default defineStore('confirm', () => {
  const state = reactive({
    msg: '',
    isShowConfirm: false,
    handleClickSuccess: null,
  });

  async function showConfirm(message, onConfirm) {
    state.isShowConfirm = true;
    state.msg = message;
    state.handleClickSuccess = onConfirm;

    addBodyOverflowHidden();
  }

  function onClickConfirm() {
    state.handleClickSuccess?.();
    onClickClose();

    removeBodyOverflowHidden();
  }

  function onClickClose() {
    state.isShowConfirm = false;
    state.msg = '';
    state.handleClickSuccess = null;

    removeBodyOverflowHidden();
  }

  return { ...toRefs(state), showConfirm, onClickConfirm, onClickClose };
});
