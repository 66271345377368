export default {
  path: '/items',
  label: '아이템',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: '',
      label: '아이템',
      name: 'items',
      component: () => import('@Views/items/ItemsView.vue'),
    },
  ],
};
