import { sinkerAPI as axios } from '@/plugins/axios';

export default {
  signToken(memberId) {
    const params = {
      memberId: String(memberId),
    };
    let result = axios().post('auth/sign', params);
    return result;
  },
  verifyAccessToken(memberId, accessToken) {
    const params = {
      memberId: String(memberId),
      accessToken: String(accessToken),
    };
    let result = axios().post('auth/verify/access', params);
    return result;
  },
  reissueAccessToken(memberId, accessToken, refreshToken) {
    const params = {
      memberId: String(memberId),
      accessToken: String(accessToken),
      refreshToken: String(refreshToken),
    };
    let result = axios().post('auth/reissue/access', params);
    return result;
  },
};
