import CryptoJS from 'crypto-js';

export const enc = v => {
  const s = 'welcomevuejs';
  console.log(v);
  const ct = CryptoJS.AES.encrypt(v, s).toString();
  console.log(ct);
  return ct;
};

export const dec = v => {
  const s = 'welcomevuejs';
  const bt = CryptoJS.AES.decrypt(v, s);
  const dec = bt.toString(CryptoJS.enc.Utf8);
  return dec;
};
