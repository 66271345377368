import AuthAPI from "@/apis/AuthAPI";

const origin = location.origin;

export default class SnsLogin {
  constructor($router, setUserInfo) {
    window.$router = $router;
    window.setUserInfo = setUserInfo;
  }

  init() {
    this.appleLoginInit();
    this.naverLoginInit();
    this.kakaoLoginInit();
    // 구글 로그인은 따ㅣ로 컴포넌트가 있어서 init 함수를 실행하지 않음
  }

  async snsJoin(id, email, name, type) {
    const { data } = await AuthAPI.joinSns(id, email, name, type);
    console.lg(
      "snsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLogin"
    );
    console.log(data);
    console.log(
      "snsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLoginsnsnLogin"
    );
    window.setUserInfo(data.result_data);

    // localStorage.setItem('mem_id', data.result_data.mem_id);
    localStorage.setItem("temp_mem_id", data.result_data.mem_id);
    localStorage.setItem("loginInfo", type);
    localStorage.setItem("return.page", "/");

    window.$router.replace("/auth/update-auth");
  }

  async snsLogin(id, email, name, type) {
    const { data } = await AuthAPI.loginSns(id, type);
    console.log("#####################################");
    console.log(data);
    console.log("#####################################");
    if (!data.result) {
      return await this.snsJoin(id, email, name, type);
    }

    localStorage.setItem("loginInfo", type);

    window.setUserInfo(data.result_data);

    if (!data.result_data.mem_phone) {
      localStorage.setItem("temp_mem_id", data.result_data.mem_id);
      window.$router.replace("/auth/update-auth");
    } else {
      localStorage.setItem("mem_id", data.result_data.mem_id);
      window.$router.replace(localStorage.getItem("return.page") ?? "/");
    }
  }

  naverLoginInit(url = `${origin}/auth/login/naver`) {
    const naver_id_login = new window.naver_id_login(
      process.env.VUE_APP_LOGIN_KEY_NAVER,
      url
    );

    const state = naver_id_login.getUniqState();

    naver_id_login.setButton("white", 2, 40);
    naver_id_login.setDomain(origin);
    naver_id_login.setState(state);
    naver_id_login.setPopup();
    naver_id_login.init_naver_id_login();

    return naver_id_login;
  }

  kakaoLoginInit() {
    if (window.Kakao.isInitialized() === false) {
      window.Kakao.init(process.env.VUE_APP_LOGIN_KEY_KAKAO);
    }
  }

  googleLoginInit() {
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_LOGIN_KEY_GOOGLE,
      callback: googleRequestSuccess,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("g_id_signin"),
      { shape: "pill", type: "icon", width: 200 } // customization attributes
    );
  }
  getGoogleLoginParams() {
    return {
      client: {
        client_id: process.env.VUE_APP_LOGIN_KEY_GOOGLE,
        prompt: "select_account",
        ux_mode: "popup",
      },
      render: { shape: "pill", type: "icon", width: 40, longtitle: false },
    };
  }
  appleLoginInit() {
    window.AppleID.auth.init({
      clientId: process.env.VUE_APP_LOGIN_KEY_APPLE,
      scope: "name email",
      redirectURI: process.env.VUE_APP_APPLE_RETURN_RUL,
      state: "signin",
      usePopup: true, //or false defaults to false
    });
  }

  naverLogin() {
    document.querySelector("#naver_id_login a").click();
  }

  kakaoLogin() {
    window.Kakao.Auth.login({
      success: kakaoLoginSuccess,
    });
  }

  appleLogin() {
    document.querySelector("#appleid-signin").click();
  }

  googleLogin() {
    const $ = window.$;
    document.querySelector("#google-signin-btn-0").click();
    // document.querySelector("#g_id_signin a").click();
    console.log($("#g_id_signin"));
  }
  googleRequestSuccess(response) {
    const profile = response.getBasicProfile();

    Auth.snsLogin(
      profile.getId(),
      profile.getEmail(),
      profile.getName(),
      "google"
    );
    // location.href = "/";
  }
}

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, function (m, p) {
      var code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = "0" + code;
      }
      return "%" + code;
    })
  );
}

function base64_url_decode(str) {
  var output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw "Illegal base64url string!";
  }

  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return atob(output);
  }
}

const Auth = new SnsLogin();

document.addEventListener("AppleIDSignInOnSuccess", (data) => {
  const id = data.detail.authorization.id_token;
  const result = JSON.parse(base64_url_decode(id.split(".")[1]));
  const email = result.email;

  let name = "";

  if (data.detail.user) {
    const user = data.detail.user;
    name = `${user.name.lastName}${user.name.firstName}`;
  }

  Auth.snsLogin(email, email, name, "apple");
});

const kakaoLoginSuccess = () => {
  window.Kakao.API.request({
    url: "/v2/user/me",
    success: kakaoRequestSuccess,
  });
};

const kakaoRequestSuccess = (res) => {
  Auth.snsLogin(
    res.id,
    res.kakao_account.email,
    res.kakao_account.profile.nickname,
    "kakao"
  );
};

const googleRequestSuccess = (response) => {
  const token = response.credential.split(".")[1];
  const info = JSON.parse(base64_url_decode(token));

  Auth.snsLogin(info.email, info.email, info.name, "google");
};
