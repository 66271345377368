<template>
  <!--선택상품 삭제시 팝업-->
  <div class="layout_popup pick_layout_pop open" style="display: block">
    <div class="dim"></div>
    <div class="layout_contain fix_foot_btn">
      <div class="layout_box">
        <div class="pop_body">
          <p class="fontSuit" v-html="msg" />
        </div>
        <div class="pop_foot">
          <div class="pop_foot">
            <button type="button" @click="onClickClose">
              {{ $t('Popup.Cancel') }}
            </button>
            <button type="button" class="black_btn" @click="onClickConfirm">
              {{ $t('Popup.Confirm') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useConfirm from '@/composables/dialogs/useConfirm';

export default {
  name: 'ConfirmDialog',
  setup() {
    return useConfirm();
  },
};
</script>

<style scoped>
.layout_contain.fix_foot_btn {
  animation: fadeInUp 0.5s;
}
</style>
