<template>
  <div class="layout_popup folder_layout_pop open" v-bind="$attrs">
    <div class="dim" @click="$emit('close')" />
    <div class="layout_contain">
      <div class="layout_box">
        <div class="pop_header">
          <h3 class="title_modal stand">{{ title }}</h3>
          <button
            type="button"
            class="close btn_dim_close"
            @click="$emit('close')"
          />
        </div>
        <div class="pop_body">
          <slot body="body" name="body"></slot>
        </div>
        <div class="pop_foot">
          <slot footer="footer" name="footer">
            <button type="summit" class="black_btn" @click="$emit('confirm')">
              {{ $t('Review.Complete') }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../utils/CommonUtils';

export default {
  props: ['title'],
  mounted() {
    addBodyOverflowHidden();
  },
  destroyed() {
    removeBodyOverflowHidden();
  },
};
</script>

<style></style>
