import { axios } from '@/plugins/axios';

export default {
  login(params) {
    localStorage.setItem('myauth', 'checked');
    localStorage.setItem('reloaded', 'checked');
    return axios.post('/member/login_p', params);
  },
  loginSns(mem_userid, mem_sns_type) {
    localStorage.setItem('myauth', 'checked');
    localStorage.setItem('reloaded', 'checked');
    return axios.post('/member/login_sns_p', {
      mem_userid,
      mem_sns_type,
    });
  },
  join(params) {
    return axios.post('/member/join_p', params);
  },
  joinSns(mem_userid, mem_email, mem_username, mem_sns_type) {
    return axios.post('/member/join_sns_p', {
      mem_userid,
      mem_email,
      mem_username,
      mem_sns_type,
    });
  },
  checkId(id) {
    return axios.post('/member/check_id_p', { id });
  },
  findIdByPhone(params) {
    return axios.post('/member/find_id_p', params);
  },

  findPwByPhone(params) {
    return axios.post('/member/find_pw_p', params);
  },
  changePassWord(params) {
    return axios.post('/member/get_reset_pw_setting_p', params);
  },
  findIdByEmail(params) {
    return axios.post('/member/find_id_email_p', params);
  },

  findPwByEmail(params) {
    return axios.post('/member/find_pw_email_p', params);
  },

  resetPw(params) {
    return axios.post('/member/reset_pw_p', params);
  },

  getMemberAuthInfo(params) {
    return axios.post('/member/getMemberAuthInfo', params);
  },

  getMemberInfo(mem_id) {
    return axios.post('/member/member_info', { mem_id });
  },
  getPhoneInfo(params) {
    return axios.post('/member/getPhoneInfo', params);
  },
  getConceptItems() {
    return axios.post('/member/getConceptItems');
  },
  checkAuth(code) {
    return axios.post('/member/checkAuth', { code });
  },

  getStyleItems() {
    return axios.post('/member/getStyleItems');
  },
  findIdSns(params) {
    return axios.post('/member/find_id_sns', params);
  },

  withdrawal(params) {
    return axios.post('/member/withdrawal', params);
  },

  createSnsId(params) {
    return axios.post('/member/create_sns_id', params);
  },

  updateMemberInfo(params) {
    return axios.post('/member/update_member_info', params);
  },

  updateProfile(params) {
    return axios.post('/member/ajaxUpdateUser2', params);
  },
  updateUserInfo(params) {
    return axios.post('/member/ajaxUpdateUser', params);
  },
  updateUserInfo2(params) {
    return axios.post('/member/updateUserInfo2', params);
  },
  updateUserStart(params) {
    return axios.post('/member/ajaxUpdateUserStart', params);
  },
  authUserInfo(params) {
    return axios.post('/member/authUserInfo', params);
  },
  getUserIds(ids) {
    return axios.post('/member/getUserIds', { ids });
  },
  getMemberAutoLoginYn(params) {
    return axios.post('/member/postUserAutoLogin', params); //자동 로그인 Yn 조회
  },
  getUserGrade(params) {
    return axios.post('/member/getUserGrade', params);
  },
  setUserPushYn(params) {
    return axios.post('/member/postUserPushYN', params);
  },
};
