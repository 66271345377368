export default {
  path: '/brand',
  label: '브랜드',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: '',
      label: '브랜드',
      name: 'brand',
      component: () => import('@Views/brands/BrandView.vue'),
    },
    {
      path: 'detail/:id',
      name: 'brand.detail',
      label: '브랜드 상세',
      component: () => import('@Views/brands/BrandDetailView.vue'),
    },
    {
      path: 'look-book',
      name: 'brand.look.book',
      label: '룩북',
      component: () => import('@Views/brands/LookBookView.vue'),
    },
    {
      path: 'look-book/:id',
      name: 'brand.look.book.detail',
      label: '룩북 디테일',
      component: () => import('@Views/brands/popups/LookBookDetail.vue'),
    },
  ],
};
