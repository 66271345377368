import Vue from "vue";
import App from "./App.vue";
import pinia from "./plugins/pinia";
import router from "./router";

import "@/utils/GlobalUtils";

import "@/assets/css/common.css";
import "@/assets/css/user.css";
import "@/assets/css/swiper.css";
import VueClipboard from "vue-clipboard2";
import { i18n } from "@/plugins/i18n/i18n";


Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

new Vue({
  router,
  pinia,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
