// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const loadImage = fileName =>
  require(`@/assets/images/design/${fileName}`);

export const toggleBodyClassNameHidden = () => {
  const name = document.body.className.split(' ');

  const idx = name.indexOf('hidden');

  idx > -1 ? name.splice(idx, 1) : name.push('hidden');

  document.body.className = name.join(' ');
};

export const addBodyOverflowHidden = () => {
  document.body.classList.add('hidden');
  // disableBodyScroll(document.body);
};

export const removeBodyOverflowHidden = () => {
  document.body.classList.remove('hidden');
  if (isLock) return;
  // enableBodyScroll(document.body);
};

let isLock = false;

export const lockBodyClassnameHidden = () => {
  isLock = true;
};

export const unlockBodyClassnameHidden = () => {
  isLock = false;
};

export const calculatePrice = price => {
  let priceValue = 0;
  price.map(cuponPrice => {
    priceValue += cuponPrice.cupon_price;
  });
  console.log('-> priceValue', priceValue);
};
