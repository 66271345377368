import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import CommonAPI from '@/apis/CommonAPI';

export default defineStore('useShopInfo', () => {
  const info = ref(null);

  const getShopInfo = async () => {
    if (info.value !== null) {
      return info.value;
    }

    const { data } = await CommonAPI.getShopInfo();

    info.value = data.result_data;

    return info.value;
  };

  return {
    info,
    getShopInfo,
  };
});
