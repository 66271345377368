export default {
  path: '/calendar',
  label: '캘린더',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: '',
      name: 'calendar',
      label: '캘린더',
      component: () => import('@Views/calendars/CalendarView.vue'),
    },
  ],
};
