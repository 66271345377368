import { reactive, toRefs } from '@vue/composition-api';
import { defineStore } from 'pinia';

export default defineStore('toast', () => {
  const state = reactive({
    isShowAlert: false,
    items: [],
    handleClickSuccess: null,
  });

  function addMsg(message) {
    const newItems = [...state.items, { msg: message, id: Date.now() }];

    state.items = newItems;

    return state.items;
  }

  function closeMsg(item) {
    const newItems = [...state.items].filter(item2 => item2 !== item);

    state.items = newItems;

    return state.items;
  }

  return { ...toRefs(state), addMsg, closeMsg };
});
