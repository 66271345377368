import Vue from 'vue';
import VueRouter from 'vue-router';
import CartRoute from './Cart';
import PickRoute from './Pick';
import AuthRoute from './Auth';
import BrandRoute from './Brand';
import CalendarRoute from './Calendar';
import MypageRoute from './Mypage';
import ItemRoute from './Item';
import OrderRoute from './Order';
import ProductRoute from './Product';
import SearchRoute from './Search';
import EventRoute from './Event';

import useUserInfo from '@/composables/user/useUserInfo';
import usePick from '@/composables/user/usePick';
import useShopInfo from '@/composables/shop/useShopInfo';
// import SessionCheck from '@/apis/SessionCheck';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@Layouts/EmptyLayout.vue'),

    children: [
      {
        path: '/',
        name: 'main',
        label: '메인 페이지',
        beforeEnter(to, from, next) {
          const isTutorial = localStorage.getItem('is_tutorial');

          isTutorial ? next() : next('/tutorial');
        },
        component: () => import('@Views/MainView.vue'),
      },
      {
        path: '/tutorial',
        name: 'tutorial',
        label: '튜토리얼',
        component: () => import('@Views/TutorialView.vue'),
      },
      AuthRoute,
      CartRoute,
      BrandRoute,
      CalendarRoute,
      MypageRoute,
      ProductRoute,
      ItemRoute,
      PickRoute,
      OrderRoute,
      SearchRoute,
      EventRoute,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let isDefaultValuesLoad = false;

const loadDefaultValues = async () => {
  const { getUserInfo } = useUserInfo();

  const info = await getUserInfo();

  if (isDefaultValuesLoad === false) {
    const { getShopInfo } = useShopInfo();
    const { getUserPick } = usePick();

    await getShopInfo();
    await getUserPick('all');

    isDefaultValuesLoad = true;
  }

  return info;
};

router.beforeEach((to, from, next) => {
  loadDefaultValues().then(data => {
    localStorage.setItem('return.page', from.path);
    // SessionCheck.checkSessionCheck(
    //   localStorage.getItem('mem_id'),
    //   localStorage.getItem('session_time'),
    //   localStorage.getItem('push_yn'),
    //   '라우터'
    // );
    console.log(from);
    console.log(to);
    if (!to.name) {
      return next('/');
    }

    if (to.name.split('.')[0] === 'auth') {
      return next();
    }

    if (data) {
      if (data.is_create_info === 'n') {
        return next({ path: '/auth/join2' });
      }
    }

    return next();
  });
});

export const checkUserLogin = (to, from, next) => {
  const { info } = useUserInfo();

  localStorage.setItem('return.page', to.fullPath);

  !info ? next('/auth/login') : next();
};

export default router;
