export default {
  path: '/cart',
  label: '장바구니',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: '',
      name: 'cart',
      label: '장바구니',
      component: () =>
        import(/* webpackChunkName: "order" */ '@Views/cart/CartView.vue'),
    },
  ],
};
