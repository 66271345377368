import { defineStore } from 'pinia';
import BrandAPI from '@/apis/BrandAPI';
import ProductAPI from '@/apis/ProductAPI';
import useUserInfo from './useUserInfo';

export default defineStore('usePick', () => {
  const { info: user } = useUserInfo();

  const info = {
    items: null,
    brands: null,
  };

  const getUserPick = async type => {
    if (!user) return [];

    if (type === 'all') {
      await updateFilterInfo('items');
      await updateFilterInfo('brands');
    } else if (!info[type]) {
      await updateFilterInfo(type);
    }

    return info[type];
  };

  const updateFilterInfo = async type => {
    if (type === 'items') {
      const { data } = await ProductAPI.getMyPickItems({
        page: 0,
        limit: 5000,
        mem_id: user.mem_id,
      });

      info.items = data.result_data ?? [];
    }

    if (type === 'brands') {
      const { data } = await BrandAPI.getMyPickBrands({
        mem_id: user.mem_id,
      });
      info.brands = data.result_data ?? [];
    }
  };

  const isPick = (type, id) => {
    if (info[type] === null) return false;

    const idAttr = type === 'items' ? 'cit_id' : 'den_id';

    const idx = info[type].findIndex(
      item => Number(item[idAttr]) === Number(id),
    );

    return idx > -1;
  };

  const update = async type => {
    if (type === 'all') {
      await updateFilterInfo('items');
      await updateFilterInfo('brands');
    } else if (info[type]) {
      await updateFilterInfo(type);
    }
  };

  return { getUserPick, isPick, update };
});
