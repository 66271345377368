export default {
  path: '/pick',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: '',
      name: 'pick',
      label: '상품 상세',
      component: () =>
        import(/* webpackChunkName: "pick" */ '@Views/picks/ItemView.vue'),
    },
    {
      path: 'brand',
      name: 'pick.brand',
      label: '상품 상세',
      component: () =>
        import(/* webpackChunkName: "pick" */ '@Views/picks/PickBrandView.vue'),
    },
  ],
};
