import { reactive, toRefs } from '@vue/composition-api';
import { defineStore } from 'pinia';
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../utils/CommonUtils';

export default defineStore('alert', () => {
  const state = reactive({
    isShowAlert: false,
    msg: '',
    handleClickSuccess: null,
  });

  function showAlert(message, onConfirm) {
    state.msg = message;
    state.isShowAlert = true;
    state.handleClickSuccess = onConfirm;

    addBodyOverflowHidden();
  }

  function onClickConfirm() {
    state.isShowAlert = false;
    state.msg = '';

    state.handleClickSuccess?.();

    removeBodyOverflowHidden();
  }

  return { ...toRefs(state), showAlert, onClickConfirm };
});
