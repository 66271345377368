export default {
  path: '/product',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: ':id',
      name: 'product.detail',
      label: '상품 상세',
      component: () =>
        import(
          /* webpackChunkName: "product" */ '@Views/products/ProductView.vue'
        ),
    },
    {
      path: 'review/:id',
      name: 'product.review',
      label: '상품 리뷰',
      component: () =>
        import(
          /* webpackChunkName: "product" */ '@Views/products/ReviewView.vue'
        ),
    },
    {
      path: 'review/:id/:r_id',
      name: 'product.review.detail',
      label: '상품 리뷰',
      component: () =>
        import(
          /* webpackChunkName: "product" */ '@Views/products/ReviewDetailView.vue'
        ),
    },
    {
      path: 'review-write/:cit_id/:cde_id',
      name: 'product.review.write',
      label: '상품 리뷰',
      component: () =>
        import(
          /* webpackChunkName: "product" */ '@Views/products/ReviewWriteView.vue'
        ),
    },
  ],
};
