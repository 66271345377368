import axios from 'axios';
import useToast from '@/composables/dialogs/useToast';

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

api.interceptors.request.use(config => {
  if (config.method === 'post') {
    const { data } = config;

    if (data) {
      const form = new FormData();
      const keys = Object.keys(data);

      keys.forEach(key => {
        if (data[key] !== undefined && data[key] !== null) {
          form.append(key, data[key]);
        }
      });

      config.data = form;
    }
  }

  return config;
});

const sinkerAPI = () => {
  let retryCount = 3;
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SINKER_URL,
    // baseURL: 'http://localhost:3000/api/v1/',
  });

  const onFulfilled = response => {
    if (response.data.statusCode && response.data.statusCode !== 200) {
      const { addMsg } = useToast();
      addMsg(`에러가 발생했습니다.(${response.data.statusCode})`);
      Promise.reject('에러가 발생했습니다.', response.data);
    }
    return response;
  };

  const retry = errConfig => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(axiosInstance.request(errConfig));
      }, 1000);
    });
  };

  const onRejected = error => {
    retryCount = retryCount - 1;
    console.log('onRejected', retryCount, error.response.status);
    if (retryCount >= 0 && error.config && error.response.status === 503) {
      return retry(error.config);
    }
    return Promise.reject(error);
  };
  axiosInstance.interceptors.response.use(onFulfilled, onRejected);
  return axiosInstance;
};

export { api as axios, sinkerAPI };
