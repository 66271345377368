export default {
  path: '/event',
  component: () => import('@Layouts/EmptyLayout.vue'),
  children: [
    {
      path: ':eventId',
      name: 'event.detail',
      label: '이벤트 상세',
      component: () =>
        import(/* webpackChunkName: "pick" */ '@Views/events/DetailView.vue'),
    },
  ],
};
