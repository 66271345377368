<template>
  <div id="app" @click="handleClickApp">
    <router-view />
    <alert-dialog v-if="isShowAlert" />
    <confirm-dialog v-if="isShowConfirm" />
    <sns-share-popup v-if="isShowShare" />
    <KCToast />
  </div>
</template>

<script>
import { ref } from 'vue-demi';

import AlertDialog from './components/popups/AlertDialog.vue';
import ConfirmDialog from './components/popups/ConfirmDialog.vue';

import useAlert from './composables/dialogs/useAlert';
import useConfirm from './composables/dialogs/useConfirm';
import useShare from './composables/dialogs/useShare';
import KCToast from './components/ui/KCToast/KCToast.vue';
import SnsSharePopup from './components/popups/SnsSharePopup.vue';

export default {
  components: { AlertDialog, ConfirmDialog, KCToast, SnsSharePopup },
  setup() {
    const alertState = useAlert();
    const isShowAlert = ref(alertState.isShowAlert);

    alertState.$subscribe((_, state) => {
      isShowAlert.value = state.isShowAlert;
    });

    const confirmState = useConfirm();
    const isShowConfirm = ref(confirmState.isShowConfirm);

    confirmState.$subscribe((_, state) => {
      isShowConfirm.value = state.isShowConfirm;
    });

    const shareState = useShare();
    const isShowShare = ref(shareState.isShowShare);

    shareState.$subscribe((_, state) => {
      isShowShare.value = state.isShowShare;
    });

    const closeSelectBox = node => {
      const classes = node.className.split(' ');

      const idx = classes.indexOf('on');

      idx > -1 && classes.splice(idx, 1);

      node.className = classes.join(' ');
    };

    const handleClickApp = () => {
      document
        .querySelectorAll('.phone_input .select_box')
        .forEach(closeSelectBox);
    };

    return {
      isShowAlert,
      isShowConfirm,
      isShowShare,
      handleClickApp,
    };
  },
};
</script>
