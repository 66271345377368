import { axios } from "@/plugins/axios";
// import KinesisAPI from './KinesisAPI';

export default {
  async getProduct(id) {
    // KinesisAPI.postTrackingClickLog(id, 'getProduct');
    return axios.post("product/getProduct", { id });
  },

  getCartItems(params) {
    return axios.post("product/getCartItems", params);
  },

  getProductDetail(id) {
    return axios.post("product/getProductDetail", { id });
  },

  getProductReview(params) {
    return axios.post("product/getProductReview", params);
  },
  //리뷰
  getReviewDetail(id) {
    return axios.post("product/getReviewDetail", { id });
  },

  updateReviewDetail(params) {
    return axios.post("product/updateReviewDetail", params);
  },

  deleteReviewDetail(params) {
    return axios.post("product/deleteReviewDetail", params);
  },

  deleteReviewFile(params) {
    return axios.post("product/deleteReviewFile", params);
  },

  getReviewComment(params) {
    return axios.post("product/getReviewComment", params);
  },

  getItemsByType(params) {
    return axios.post("product/getItemsByType", params);
  },

  // useFilterInfo에서 자동으로 매칭하기 위해 _를 사용
  get_category() {
    return axios.post("product/getProductCategories");
  },

  get_concept() {
    return axios.post("product/getConcepts");
  },

  get_color() {
    return axios.post("product/getColors");
  },

  get_style() {
    return axios.post("product/getStyles");
  },

  getItemsMaxPrice() {
    return axios.post("product/getItemsMaxPrice");
  },

  getMyReviews(params) {
    return axios.post("product/getMyReviews", params);
  },

  getMyPickItems(params) {
    return axios.post("product/getMyPickItems", params);
  },

  getMyFolders(params) {
    return axios.post("product/getMyFolders", params);
  },

  getProductOptions(id) {
    return axios.post("product/getProductOptions", { id });
  },
  getItemByProductIds(params) {
    return axios.post("product/getItemByProductIds", params);
  },
  deleteComment(params) {
    return axios.post("product/deleteComment", params);
  },

  createReview(params) {
    return axios.post("product/createReview", params);
  },
  createComment(params) {
    return axios.post("product/createComment", params);
  },
  createFolder(params) {
    return axios.post("product/createFolder", params);
  },

  moveFolder(params) {
    return axios.post("product/moveFolder", params);
  },

  deleteFolder(params) {
    return axios.post("product/deleteFolder", params);
  },

  updateFolderName(params) {
    return axios.post("product/updateFolderName", params);
  },

  updateCartItem(params) {
    return axios.post("product/updateCartItem", params);
  },
  updateFolderOrder(params) {
    return axios.post("product/updateFolderOrder", params);
  },

  deleteCartItems(ids) {
    return axios.post("product/deleteCartItems", { ids });
  },

  addCartItems(params) {
    return axios.post("product/addCartItems", params);
  },
  addCartegory(params) {
    return axios.post("product/cartegory", params);
  },
  get_review_write_chk(params) {
    return axios.post("product/get_review_write_chk", params);
  },
  get_product_review_options(params) {
    return axios.post("product/getProductOptions", params);
  },
};
