import { axios } from "@/plugins/axios";

export default {
  getBrand(params) {
    return axios.post("brand/getBrand", params);
  },
  getBrands(params) {
    return axios.post("brand/getBrands", params);
  },
  getBrandsRank(params) {
    return axios.post("brand/getBrandsRank", params);
  },
  getNewBrands(params) {
    return axios.post("brand/getNewBrands", params);
  },
  getMyPickBrands(params) {
    return axios.post("brand/getMyPickBrands", params);
  },

  getLookBookItem(params) {
    return axios.post("brand/getLookBookItem", params);
  },
  getLookbookList(params) {
    return axios.post("brand/getLookbookList", params);
  },
  getOrderLookBooks(params) {
    return axios.post("brand/getOrderLookBooks", params);
  },
  getRangKingBrand(params) {
    return axios.post("/api/v1/search/provider", params);
  },
  getBrandData(params) {
    return axios.post("brand/getAllBrands", params);
  },
  getBrandReturnFee(params) {
    return axios.post("brand/getBrandReturnFee", params);
  },
};
