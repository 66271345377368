import { checkUserLogin } from "@/router";

export default {
  path: "/mypage",
  component: () => import("@Layouts/EmptyLayout.vue"),
  children: [
    {
      path: "/",
      name: "mypage",
      label: "마이페이지",
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/MypageView.vue"
        ),
    },
    {
      path: "withdrawal",
      name: "mypage.withdrawal",
      label: "회원탈퇴",
      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/WithdrawalView.vue"
        ),
    },
    {
      path: "update-info",
      name: "mypage.update.info",
      label: "회원 정보 수정 인증페이지(로그인)",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/UpdateInfoView.vue"
        ),
    },
    {
      path: "update-info-form",
      name: "mypage.update.info.form",
      label: "회원정보 수정",
      beforeEnter: (to, from, next) => checkUserLogin(to, from, next),
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/UpdateInfoFormView.vue"
        ),
    },
    {
      path: "create-info-form",
      name: "mypage.create.info.form",
      label: "회원정보 수정 계정이 없는 경우 회원가입",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/CreateInfoView.vue"
        ),
    },
    {
      path: "profile",
      name: "mypage.profile",
      label: "프로필 설정",
      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/ProfileView.vue"
        ),
    },
    {
      path: "coupon",
      name: "mypage.coupon",
      label: "쿠폰",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/coupons/CouponView.vue"
        ),
    },
    {
      path: "review",
      name: "mypage.review",
      label: "리뷰",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/reviews/ReviewView.vue"
        ),
    },
    {
      path: "point",
      name: "mypage.point",
      label: "포인트",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(/* webpackChunkName: "mypage" */ "@Views/mypages/PointView.vue"),
    },
    {
      path: "recently",
      name: "mypage.recently",
      label: "최근 본 항목",
      component: () =>
        import(
          /* webpackChunkName: "mypage-shopping" */ "@Views/mypages/RecentlyView.vue"
        ),
    },
    {
      path: "status-change-order",
      name: "mypage.status.change.order",
      label: "취소/교환/환불내역",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage-order" */ "@Views/mypages/status-change-order/StatusChangeOrderView.vue"
        ),
    },
    {
      path: "transaction-proof",
      name: "mypage.transaction.proof",
      label: "거래증빙서류",
      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage-order" */ "@Views/mypages/transaction-proof/TransactionProofView.vue"
        ),
    },
    {
      path: "order-cancel/:order_id/:cod_id",
      name: "mypage.order.cancel",
      label: "주문 취소 상세",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage-order" */ "@Views/mypages/status-change-order/CancelDetailView.vue"
        ),
    },
    {
      path: "order-change-return/:order_id/:cod_id",
      name: "mypage.order.change.return",
      label: "교환/반품 상세",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage-order" */ "@Views/mypages/status-change-order/ChangeReturnDetailView.vue"
        ),
    },
    {
      path: "delivery-list",
      name: "mypage.delivery.list",
      label: "배송지 조회",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage-order" */ "@Views/mypages/delivery-list/DeliveryListView.vue"
        ),
    },
    {
      path: "order-delivery-search",
      name: "mypage.order.delivery.search",
      label: "주문/배송 조회",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage-order" */ "@Views/mypages/order-delivery-search/OrderDeliverySearchView.vue"
        ),
    },
    {
      path: "order/:id",
      name: "mypage.order.delivery.search.detail",
      label: "주문/배송 조회",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage-order" */ "@Views/mypages/order-delivery-search/OrderDetailView.vue"
        ),
    },

    {
      path: "service/direct-inquiry",
      name: "mypage.service.direct",
      label: "나의 1:1 문의",

      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage-service-center" */ "@Views/service-centers/DirectInquiry.vue"
        ),
    },
    {
      path: "digital",
      label: "디지털보증서",
      name: "mypage.digital.main",
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/digital-warranties/MainView.vue"
        ),
    },
    {
      path: "digital/:id/:seq/:owner_id",
      label: "디지털보증서 상세",
      name: "mypage.digital.detail",
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/digital-warranties/DetailView.vue"
        ),
    },
    {
      path: "setting",
      label: "설정",
      name: "mypage.setting",
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/settings/SettingView.vue"
        ),
    },
    {
      path: "notice",
      label: "공지사항",
      name: "mypage.notice",
      component: () =>
        import(/* webpackChunkName: "mypage" */ "@Views/notice/NoticeView.vue"),
    },
    {
      path: "faq",
      label: "FAQ",
      name: "mypage.faq",
      component: () =>
        import(/* webpackChunkName: "mypage" */ "@Views/notice/FAQView.vue"),
    },
    {
      path: "mystyle",
      label: "마이스타일",
      name: "mypage.mystyle",
      beforeEnter: (...attr) => checkUserLogin(...attr),
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/mypages/MyStyleView.vue"
        ),
    },
    {
      path: "inquiry-store",
      label: "입점/제휴문의",
      name: "mypage.store",
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ "@Views/service-centers/InquiryStoreView.vue"
        ),
    },
  ],
};
